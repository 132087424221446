<template>
  <div class="ud-body">
    <!-- 搜索表单 -->
    <a-card :bordered="true">
      <a-form
        :model="where"
        :label-col="{ md: { span: 8 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 16 }, sm: { span: 24 } }"
      >
        <a-row>
          <a-col :lg="8" :md="12" :sm="24" :xs="24">
            <a-form-item label="租赁点名称:">
              <a-input
                v-model:value.trim="where.pointName"
                placeholder="请输入租赁点名称"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24" :xs="24">
            <a-form-item label="类型:" name="pointType">
              <a-select
                v-model:value="where.pointType"
                placeholder="请选择类型"
                allow-clear
              >
              <a-select-option
                  v-for="item in pointTypeList"
                  :key="item.dictDataId"
                  :value="item.dictDataCode"
                >
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24" :xs="24">
            <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
              <a-space>
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <ud-pro-table
        ref="table"
        row-key="pointId"
        :datasource="datasource"
        :columns="columns"
        v-model:selection="selection"
        :scroll="{ x: 'max-content' }"
      >
        <template #pointType="{ text }">
          <span :class="typeClass(text)">
            {{ typeName(text) }}
          </span>
        </template>
        <template #pointProgress="{ record }">
          <a-progress :percent="record.pointProgress" size="small" />
        </template>
        <template #toolbar>
          <a-space>
            <a-button type="primary" @click="openEdit()">
              <template #icon>
                <plus-outlined />
              </template>
              <span>新建</span>
            </a-button>
            <a-button type="primary" danger @click="removeBatch">
              <template #icon>
                <delete-outlined />
              </template>
              <span>删除</span>
            </a-button>
          </a-space>
        </template>
        <template #enable="{ text, record }">
          <a-switch
            :checked="text === 0"
            @change="(checked) => editEnabled(checked, record)"
          />
        </template>
        <template #action="{ record }">
          <a-space>
            <a @click="openDetail(record)">详情</a>
            <a @click="openEdit(record)">修改</a>
            <a-divider type="vertical" />
            <a-popconfirm
              title="确定要删除此项目吗？"
              @confirm="remove(record)"
            >
              <a class="ud-text-danger">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </ud-pro-table>
    </a-card>
    <!-- 编辑弹窗 -->
    <BranchEdit v-model:visible="showEdit" :data="current" @done="reload" />
  </div>
  <!-- 详情弹窗 -->
  <OperRecordDetail v-model:visible="showInfo" :data="current" @done="reload" />
</template>

<script>
import * as pointApi from '@/api/rnt/point'
import * as dictDataApi from '@/api/rnt/pointtype'
import BranchEdit from './point-edit'
import OperRecordDetail from './oper-record-detail'
import { createVNode } from 'vue'
import {
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons-vue'

export default {
  name: 'pointList',
  components: {
    PlusOutlined,
    DeleteOutlined,
    BranchEdit,
    OperRecordDetail
  },
  data() {
    return {
      checked1: false,
      name: 'pointList',
      // 表格列配置
      columns: [
        {
          key: 'index',
          width: 48,
          customRender: ({ index }) => this.$refs.table.tableIndex + index
        },
        // 租赁点名称
        {
          title: '租赁点名称',
          dataIndex: 'pointName',
          sorter: true,
          slots: { customRender: 'pointName' }
        },
        // 所属站点组
        {
          title: '所属站点组',
          dataIndex: 'groupName',
          sorter: true
        },
        // 管理人
        {
          title: '管理人',
          dataIndex: 'manager',
          sorter: true
        },
        // 运营商
        {
          title: '运营商',
          dataIndex: 'operatorName',
          sorter: true
        },
        // 营运地址
        {
          title: '营运地址',
          dataIndex: 'address',
          sorter: true
        },
        // 类型
        {
          title: '类型',
          dataIndex: 'pointType',
          sorter: true,
          slots: { customRender: 'pointType' }
        },
        // 启用
        {
          title: '启用',
          dataIndex: 'enable',
          sorter: true,
          width: 90,
          align: 'center',
          slots: { customRender: 'enable' }
        },
        // 操作
        {
          title: '操作',
          key: 'action',
          width: 200,
          align: 'center',
          slots: { customRender: 'action' }
        }
      ],
      // 表格搜索条件
      where: {},
      // 表格选中数据
      selection: [],
      // 是否显示编辑弹窗
      showEdit: false,
      showInfo: false,
      // 当前编辑数据
      current: null,
      // 编辑表单提交状态
      editLoading: false,
      // 类型
      pointTypeList: []
    }
  },
  mounted() {
    this.queryPointType()
  },
  methods: {
    /* 查询租赁点类型 */
    queryPointType() {
      dictDataApi
        .pointType()
        .then((res) => {
          if (res.code === 0) {
            this.pointTypeList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 修改用户状态 */
    editEnabled(checked, row) {
      const data = {
        enable: checked ? 0 : 1,
        pointId: row.pointId
      }
      pointApi
        .enable(data)
        .then((res) => {
          if (res.code === 0) {
            row.enable = checked ? 0 : 1
            this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    typeClass(dictDataCode) {
      if (dictDataCode === 'join') {
        return 'ud-text-success'
      }
      if (dictDataCode === 'autarky') {
        return 'ud-text-warning'
      }
    },
    typeName(dictDataName) {
      if (dictDataName === 'join') {
        return '加盟'
      }
      if (dictDataName === 'autarky') {
        return '自营'
      }
    },
    datasource(option, callback) {
      pointApi
        .page({
          ...this.where,
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 刷新表格 */
    reload() {
      this.selection = []
      this.$refs.table.reload({ page: 1 })
    },
    /* 重置搜索 */
    reset() {
      this.where = {}
      this.$nextTick(() => {
        this.reload()
      })
    },
    /* 删除单个 */
    remove(row) {
      const hide = this.$message.loading('请求中..', 0)
      pointApi
        .deleteById(row.pointId)
        .then((res) => {
          hide()
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.reload()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
    },
    /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        this.$message.error('请至少选择一条数据')
        return
      }
      this.$confirm({
        title: '提示',
        content: '确定要删除选中的项目吗?',
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading('请求中..', 0)
          const data = { data: this.selection.map((d) => d.pointId) }
          pointApi
            .deleteBatch(data)
            .then((res) => {
              hide()
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.reload()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              hide()
              this.$message.error(e.message)
            })
        }
      })
    },
    /* 打开编辑弹窗 */
    openEdit(row) {
      this.current = row
      this.showEdit = true
    },
    /* 详情 */
    openDetail(row) {
      this.current = row
      this.showInfo = true
    }
  }
}
</script>

<style scoped>

</style>
