<!-- 详情弹窗 -->
<template>
  <a-modal
    title="详情"
    :width="640"
    :footer="null"
    :visible="visible"
    @update:visible="updateVisible"
  >
    <a-form
      class="ud-form-detail"
      :label-col="{ sm: { span: 8 }, xs: { span: 6 } }"
      :wrapper-col="{ sm: { span: 16 }, xs: { span: 18 } }"
    >
      <a-row :gutter="16">
        <a-col :sm="12" :xs="24">
          <!-- 租赁点名称 -->
          <a-form-item label="租赁名称:">
            <div class="ud-text-secondary">
              {{ data.pointName }}
            </div>
          </a-form-item>
          <!-- 租赁点类型 -->
          <a-form-item label="租赁类型:">
            <span :class="typeClass(data.pointType)">
              {{ typeName(data.pointType) }}
            </span>
          </a-form-item>
          <!-- 管理人 -->
          <a-form-item label="管理人:">
            <div class="ud-text-secondary">
              {{ data.manager }}
            </div>
          </a-form-item>
          <!-- 所属站点组 -->
          <a-form-item label="所属站点组:">
            <div class="ud-text-secondary">
              {{ data.groupName }}
            </div>
          </a-form-item>
          <!-- 营运开始时间 -->
          <a-form-item label="营运结束时间:">
            <div class="ud-text-secondary">
              {{ data.startTime }}
            </div>
          </a-form-item>
          <!-- 营运结束时间 -->
          <a-form-item label="营运结束时间:">
            <div class="ud-text-secondary">
              {{ data.endTime }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="12" :xs="24">
          <!-- 运营商 -->
          <a-form-item label="运营商:">
            <div class="ud-text-secondary">
              {{ data.operatorName }}
            </div>
          </a-form-item>
          <!-- 手机号 -->
          <a-form-item label="手机号:">
            <div class="ud-text-secondary">
              {{ data.phone }}
            </div>
          </a-form-item>
          <!-- 省市区 -->
          <a-form-item label="省市区:">
            <div class="ud-text-secondary">
              {{ fullCity }}
            </div>
          </a-form-item>
          <!-- 营运地址 -->
          <a-form-item label="营运地址:">
            <div class="ud-text-secondary">
              {{ data.address }}
            </div>
          </a-form-item>
          <!-- 经度 -->
          <a-form-item label="经度:">
            <div class="ud-text-secondary">
              {{ data.longitude }}
            </div>
          </a-form-item>
          <!-- 纬度 -->
          <a-form-item label="纬度:">
            <div class="ud-text-secondary">
              {{ data.latitude }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="12" :xs="24">
          <!-- 租赁点照片 -->
          <a-form-item label="租赁点照片:">
            <div class="ud-text-secondary">
              <img class="photo" :src="data.pointPhoto" width="100" />
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="12" :xs="24">
          <!-- 营业执照 -->
          <a-form-item label="营业执照:">
            <div class="ud-text-secondary">
              <img class="photo" :src="data.businessLicense" width="100" />
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="12" :xs="24">
          <!-- 管理员身份证(正面) -->
          <a-form-item label="身份证(正面):" >
            <div class="ud-text-secondary">
              <img class="photo" :src="data.idcardPic" width="100" />
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="12" :xs="24">
          <!-- 管理员身份证(反面) -->
          <a-form-item label="身份证(反面):">
            <div class="ud-text-secondary">
              <img class="photo" :src="data.idcardPic2" width="100" />
            </div>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import regions from '@/utils/regions'
export default {
  name: 'OperRecordDetail',
  emits: ['update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 数据
    data: Object
  },
  data() {
    return {
      fullCity: ''
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.getLabel()
      }
    }
  },
  methods: {
    typeClass(dictDataCode) {
      if (dictDataCode === 'join') {
        return 'ud-text-success'
      }
      if (dictDataCode === 'autarky') {
        return 'ud-text-warning'
      }
    },
    typeName(dictDataName) {
      if (dictDataName === 'join') {
        return '加盟'
      }
      if (dictDataName === 'autarky') {
        return '自营'
      }
    },
    getLabel() {
      this.fullCity =
        regions.getLabel(this.data.province) +
        regions.getLabel(this.data.city) +
        regions.getLabel(this.data.district)
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style scoped>
.photo{
  width: 200px;
  height: 100px;
  margin: 20px 0;
}
</style>
